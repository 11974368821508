export const baseUrl = (s) => {
  if (typeof s !== 'string') {
    s = '';
  }

  if (s[s.length - 1] !== '/' && !/\.\w+/.test(s)) {
    s += '/';
  }

  if (s.indexOf('http') === 0) {
    return s;
  }

  if (process.env.APP_URL) {
    return `${process.env.APP_URL}${s}`;
  }

  return s;
};
