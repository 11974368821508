import { baseUrl } from '../../lib/url'

const Link = ({ children, to, target = '_blank' }) => (
  <a
    className='hover:underline text-pink-500'
    href={baseUrl(to)}
    target={target}
    rel='noopener noreferrer'
  >
    {children}
  </a>
)

export default Link
