import { products as productsData, presenter as presenterData } from '../../data';

const categoriesAliases = {
  'mors-dag': 'mamma',
  'fars-dag': 'pappa',
};

export const presenter = presenterData;
export const findPresenter = (url) => {
  for (const key in presenterData) {
    const row = presenterData[key];
    for (let j = 0, k = row.length; j < k; j++) {
      for (let i = 0, l = row[j].items.length; i < l; i++) {
        if (row[j].items[i].url === url) {
          return row[j].items[i];
        }
      }
    }
  }
  return {};
};
export const allPresentPages = () => {
  let allItems = [];
  for (const key in presenterData) {
    const row = presenterData[key].items;
    for (let j = 0, k = row.length; j < k; j++) {
      allItems = allItems.concat(row[j].items);
    }
  }
  return allItems.sort((a, b) => a.title.localeCompare(b.title));
};

export const products = productsData;
export const findProducts = (categories = [], limit = 0, random = false) => {
  if (!categories.length) {
    return productsData;
  }

  let products = categories.includes('alla')
    ? productsData
    : productsData.filter((p) => {
        for (let i = 0, l = categories.length; i < l; i++) {
          let cat = categories[i];

          if (typeof categoriesAliases[cat] === 'string') {
            cat = categoriesAliases[cat];
          }

          if (p.categories.indexOf(cat) !== -1) {
            return true;
          }
        }
        return false;
      });

  if (random) {
    const randomProducts = [];
    for (let i = 0, l = limit; i < l; i++) {
      randomProducts.push(products[Math.floor(Math.random() * products.length)]);
    }
    products = randomProducts;
  } else if (limit > 0) {
    products = products.slice(0, limit);
  }

  return products.filter((p) => p);
};
export const findProductsUrl = (url) => {
  return productsData.filter((p) => {
    return p.url.indexOf(url) !== -1;
  });
};
export const findRelatedProducts = (product, limit = 3) => {
  return productsData
    .filter((p) => {
      for (let i = 0, l = product.categories.length; i < l; i++) {
        const cat = product.categories[i];

        if (p.categories.indexOf(cat)) {
          return true;
        }
      }

      return false;
    })
    .slice(0, limit);
};

export const findCategories = (product) => {
  const categories = [];
  const presenter = allPresentPages();
  for (let i = 0, l = presenter.length; i < l; i++) {
    const present = presenter[i];

    for (let j = 0, k = product.categories.length; j < k; j++) {
      const category = product.categories[j];
      if (present.categories.indexOf(category) !== -1) {
        categories.push(present);
        break;
      }
    }
  }

  return categories;
};
