import { withRouter } from 'next/router';
import Link from '../link';
import { products, allPresentPages } from '../../data';
import tinydate from 'tinydate';

const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="w-8 inline-block"
    data-icon="facebook-square"
    data-prefix="fab"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M400 32H48A48 48 0 000 80v352a48 48 0 0048 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0048-48V80a48 48 0 00-48-48z"
    />
  </svg>
);

const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="w-8 inline-block"
    data-icon="twitter-square"
    data-prefix="fab"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 01-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"
    />
  </svg>
);

const PinterestIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    className="w-8 inline-block"
    data-icon="pinterest-square"
    data-prefix="fab"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M448 80v352c0 26.5-21.5 48-48 48H154.4c9.8-16.4 22.4-40 27.4-59.3 3-11.5 15.3-58.4 15.3-58.4 8 15.3 31.4 28.2 56.3 28.2 74.1 0 127.4-68.1 127.4-152.7 0-81.1-66.2-141.8-151.4-141.8-106 0-162.2 71.1-162.2 148.6 0 36 19.2 80.8 49.8 95.1 4.7 2.2 7.1 1.2 8.2-3.3.8-3.4 5-20.1 6.8-27.8.6-2.5.3-4.6-1.7-7-10.1-12.3-18.3-34.9-18.3-56 0-54.2 41-106.6 110.9-106.6 60.3 0 102.6 41.1 102.6 99.9 0 66.4-33.5 112.4-77.2 112.4-24.1 0-42.1-19.9-36.4-44.4 6.9-29.2 20.3-60.7 20.3-81.8 0-53-75.5-45.7-75.5 25 0 21.7 7.3 36.5 7.3 36.5-31.4 132.8-36.1 134.5-29.6 192.6l2.2.8H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z"
    />
  </svg>
);

const lastestUpdated = tinydate('{YYYY}-{MM}-{DD}');

const Rules = () => {
  return (
    <div className="bg-white pt-16 pb-10 lg:pb-20 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl text-center">
            Våra 3 gyllene regler för att hitta en bra present
          </h2>
        </div>
        <div className="mt-6 lg:mt-12 grid gap-10 lg:gap-16 pt-6 lg:pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          <div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">
                1. Utgå ifrån personen, inte produkten
              </p>
              <p className="mt-3 text-base text-gray-500">
                Det är lätt att tänka att man vill utgå från en produkt, men vill den personen du
                köper presenten till verkligen ha en sån? Utgå istället från vad den personen kan
                tänkas vilja ha.
              </p>
            </a>
          </div>

          <div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">2. Köp kvalitet</p>
              <p className="mt-3 text-base text-gray-500">
                En present är nått man vill att personen ska använda och inte kasta när man gått
                hem. Det är bättre med en riktig bra present istället för två mindre bra presenter.
                Det handlar också mycket om hur du ger presenten snarare än vad det är.
              </p>
            </a>
          </div>

          <div>
            <a href="#" className="block mt-4">
              <p className="text-xl font-semibold text-gray-900">3. Gör presenten personlig</p>
              <p className="mt-3 text-base text-gray-500">
                En present ska vara personlig och är du säker på att personen inte kommer vilja byta
                presenten så kan du öppna presenten och göra den mer personlig istället för att ge
                en kartong direkt från butiken. T ex lägga dit ett kort och slå in den i ett fint
                papper.
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = ({ router }) => {
  const url = `https://allapresenter.com${router.asPath}`;

  const allPresentPagesArray = allPresentPages();

  return (
    <>
      <section className="w-full lg:px-10">
        <div className="container mx-auto">
          <Rules />
        </div>
      </section>
      <section className="w-full p-10 flex-row border-t border-solid border-gray-300">
        <div className="container mx-auto">
          <div className="text-center text-gray-800">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl text-center pb-4">
              Alla presenter
            </h2>
            <p className="text-gray-500">
              <a href="https://allapresenter.com/om-oss/" className="text-pink-500 hover:underline">
                Om oss
              </a>{' '}
              -{' '}
              <a
                href="https://allapresenter.com/om-cookies/"
                className="text-pink-500 hover:underline"
              >
                Om cookies
              </a>{' '}
              -{' '}
              <a
                href="https://allapresenter.com/sitemap/"
                className="text-pink-500 hover:underline"
              >
                Sitemap
              </a>
            </p>
            {/* <p className="mt-3 text-base text-gray-500">
              Senast uppdaterad: {lastestUpdated(new Date())}.
            </p> */}
            <p className="mt-3 text-base text-gray-500">Dela gärna vår sida om du gillar den</p>
            <p className="pt-2">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                title="Facebook"
                target="_blank"
                rel="noopener noreferrer"
                className="p-5 pl-0"
              >
                <FacebookIcon />
              </a>
              <a
                href={`http://twitter.com/share?url=${url}`}
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
                className="p-5"
              >
                <TwitterIcon />
              </a>
              <a
                href={`http://pinterest.com/pin/create/button/?url=${url}`}
                title="Pinterest"
                target="_blank"
                rel="noopener noreferrer"
                className="p-5"
              >
                <PinterestIcon />{' '}
              </a>
            </p>
          </div>
        </div>
      </section>
      {false && (
        <section className="w-full p-10 flex-row border-t border border-gray-300 text-sm">
          <div className="container mx-auto">
            <div className="text-center text-gray-500">
              <ul>
                {allPresentPagesArray.map((item, index) => (
                  <li className="inline">
                    <Link target="_self" to={item.url}>
                      {item.title}
                    </Link>
                    {index + 1 < allPresentPagesArray.length ? ' | ' : ''}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default withRouter(Footer);
