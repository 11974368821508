import App from 'next/app';
import CookieConsent from 'react-cookie-consent';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import * as Sentry from '@sentry/browser';
import Head from 'next/head';
import '../styles/globals.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://623da946513b44b5afb77b10bcbdf770@sentry.io/2410712',
  });
}

export default class Application extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <Navbar />
        <Component {...pageProps} />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="ap"
          style={{ background: '#2B373B' }}
          buttonStyle={{
            background: '#fff',
            color: '#4e503b',
            fontSize: '13px',
          }}
          expires={150}
        >
          <div>
            Vi använder{' '}
            <a href="/om-cookies" className="text-white underline">
              cookies
            </a>{' '}
            för att göra ditt presentletande så smidigt som möjligt.
          </div>
        </CookieConsent>
      </>
    );
  }
}
