import React, { useState } from 'react';
import { products, presenter } from '../../data';
import { baseUrl } from '../../lib/url';
import { Smile, Calendar, Gift, Compass, Bookmark } from 'react-feather';

const allLink = baseUrl('/presenter/alla-presenttips/');

const menuItems = [
  {
    title: 'Mottagare',
    url: '/mottagare/',
    icon: () => <Smile className="inline-block -mt-1 mr-1" />,
    items: [
      {
        title: 'Till de yngre',
        url: baseUrl('/presenter/presenter-till-barn/'),
        items: [
          {
            title: 'Presenter ill små barn',
            url: baseUrl('/presenter/presenter-till-sma-barn/'),
          },
          {
            title: 'Presenter till barn',
            url: baseUrl('/presenter/presenter-till-barn/'),
          },
        ],
      },
      {
        title: 'Till henne',
        image: '/images/grid/henne.jpg',
        url: baseUrl('/presenter/presenter-till-henne/'),
        items: [
          {
            title: 'Presenter till henne',
            url: baseUrl('/presenter/presenter-till-henne/'),
          },
          {
            title: 'Presenter till flickvännen',
            url: baseUrl('/presenter/presenter-till-flickvan/'),
          },
          {
            title: 'Presenter till mamma',
            url: baseUrl('/presenter/presenter-till-mamma/'),
          },
          {
            title: 'Presenter till kvinnor',
            url: baseUrl('/presenter/presenter-till-kvinnor/'),
          },
        ],
      },
      {
        title: 'Till honom',
        image: '/images/grid/honom.jpg',
        url: baseUrl('/presenter/presenter-till-honom/'),
        items: [
          {
            title: 'Presenter till honom',
            url: baseUrl('/presenter/presenter-till-honom/'),
          },
          {
            title: 'Presenter till pojkvännen',
            url: baseUrl('/presenter/presenter-till-pojkvan/'),
          },
          {
            title: 'Presenter till pappa',
            url: baseUrl('/presenter/presenter-till-pappa/'),
          },
          {
            title: 'Presenter till män',
            url: baseUrl('/presenter/presenter-till-man/'),
          },
        ],
      },
    ],
  },
  {
    title: 'Tillfällen',
    url: '/tillfallen/',
    icon: () => <Calendar className="inline-block -mt-1 mr-1" />,
    items: presenter.tillfallen.items
      .map((i) => i.items)
      .flat()
      .map((i) => {
        i.image = baseUrl(
          `/images/grid/${i.url
            .split('/')
            .filter((i) => i)
            .pop()
            .replace('presenter', '')
            .replace('-till', '')
            .replace(/^\-/, '')}.jpg`,
        );
        return i;
      }),
  },
  {
    title: 'Kategorier',
    url: '/kategorier/',
    icon: () => <Gift className="inline-block -mt-1 mr-1" />,
    items: presenter.kategorier.items.map((i) => i.items).flat(),
  },
  // {
  //   title: 'Presentbloggen',
  //   url: '/blogg/',
  //   icon: () => <Bookmark className="inline-block -mt-1 mr-1" />,
  // },
];

const Search = ({ className = '', id = 'search' }) => (
  <div className={`max-w-lg w-full mt-4 lg:mt-0 lg:max-w-xs ${className}`}>
    <form action="/sok/" method="GET">
      <label htmlFor={id} className="sr-only">
        Sök
      </label>
      <div className="relative">
        <button
          title="Sök"
          className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-click"
        >
          <svg className="h-5 w-5 text-gray-800" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <input
          id={id}
          name="query"
          className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-200 placeholder-gray-800 focus:outline-none sm:text-sm transition duration-150 ease-in-out"
          placeholder="Sök"
        />
      </div>
    </form>
  </div>
);

const Navitem = ({ active = false, index = null, item, onClick = () => {} }) => {
  return (
    <div className="flex">
      <div className="relative flex">
        <button
          type="button"
          className="border-transparent text-gray-700 hover:text-gray-800 relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px"
          aria-expanded="false"
          onClick={() => onClick(active ? null : index)}
        >
          {item.title}
        </button>
      </div>

      <div
        className={`absolute top-full inset-x-0 z-20 text-gray-500 sm:text-sm ${
          active ? '' : 'hidden'
        } `}
      >
        <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true"></div>

        <div className="relative bg-white">
          <div className="max-w-7xl mx-auto">
            <div className="px-8 py-4 pt-5 font-bold text-gray-900 hidden">
              <div className="flex">
                <div className="flex-grow">
                  <span>{'>'} </span>
                  <a href={item.url} className="hover:underline">
                    Visa alla {item.title.toLowerCase()}
                  </a>
                </div>
                <div>
                  <button
                    title="Stäng"
                    type="button"
                    onClick={() => onClick(null)}
                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  >
                    <span className="sr-only">Stäng</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto px-8">
            <div className="grid grid-cols-3 items-start gap-y-10 gap-x-8 pb-6 pt-6">
              {' '}
              {/* border-t border-gray-200 border-solid */}
              {item.items
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((row, rowIndex) => (
                  <div className="grid grid-cols-1 gap-y-10 gap-x-8" key={rowIndex}>
                    <div>
                      <a href={row.url} className="font-medium text-gray-900 hover:underline">
                        {row.title}
                      </a>
                      {row.items && (
                        <ul
                          role="list"
                          aria-labelledby="desktop-featured-heading-0"
                          className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                        >
                          {row.items
                            .sort((a, b) => a.title.localeCompare(b.title))
                            .map((link, linkIndex) => (
                              <li className="flex" key={linkIndex}>
                                <a href={link.url} className="hover:text-gray-800">
                                  {link.title}
                                </a>
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  return (
    <div className="bg-white">
      <div
        className={`${mobileOpen ? 'fixed' : 'hidden'} inset-0 flex z-40 lg:hidden`}
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>

        <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
          <div className="px-4 pt-5 pb-2 flex">
            <button
              type="button"
              title="Stäng"
              className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <span className="sr-only">Stäng</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="mt-2">
            <div className="px-4 pt-6 pb-6 space-y-12" role="tabpanel" tabIndex="0">
              <div className="grid grid-cols-1 items-start gap-y-5 gap-x-6">
                {menuItems.map((menuItem, menuItemIndex) => (
                  <div className="grid grid-cols-1" key={menuItemIndex}>
                    <div>
                      <a className="font-medium text-gray-900" href={menuItem.url}>
                        {menuItem.title}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="relative">
        <nav aria-label="Top">
          <div className="bg-gray-900">
            <div className="max-w-7xl mx-auto h-12 px-4 flex items-center justify-between sm:px-6 lg:px-8">
              <div className="hidden lg:block lg:flex-1"></div>

              <a
                href={allLink}
                className="flex-1 text-center text-sm font-medium text-white lg:flex-none hover:underline"
              >
                {products.length} presenter till alla tänkbara personer, tillfällen och högtider
              </a>

              <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6"></div>
            </div>
          </div>

          <div className="bg-white">
            <div className="border-b border-gray-200">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="h-16 flex items-center justify-between">
                  <div className="hidden lg:flex lg:items-center">
                    <a href="/" title="Alla Presenter">
                      <span className="sr-only">Alla Presenter</span>
                      <img
                        src="/images/logo.png"
                        alt="Alla Presenter"
                        className="h-10 w-auto lazyload"
                      />
                    </a>
                  </div>

                  <div className="hidden h-full lg:flex">
                    <div className="ml-8">
                      <div className="h-full flex justify-center space-x-8">
                        {menuItems.map((menuItem, menuItemIndex) => (
                          <Navitem
                            key={menuItemIndex}
                            item={menuItem}
                            key={menuItemIndex}
                            active={activeIndex === menuItemIndex}
                            index={menuItemIndex}
                            onClick={setActiveIndex}
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 flex items-center lg:hidden">
                    <button
                      type="button"
                      title="Öppna menyn"
                      className="-ml-2 bg-white p-2 rounded-md text-gray-400"
                      onClick={() => setMobileOpen(!mobileOpen)}
                    >
                      <span className="sr-only">Öppna menyn</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>

                    <a href="/" className="lg:hidden">
                      <span className="sr-only">Alla Presenter</span>

                      <img
                        src="/images/logo.png"
                        alt="Alla Presenter"
                        className="h-10 w-auto lazyload"
                      />
                    </a>
                  </div>

                  <div className="flex-1 flex items-center justify-end">
                    <div className="flex items-center lg:ml-8">
                      <div className="flow-root">
                        <Search className="-mt-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
